import { LitElement, css, html } from 'lit';
import { templateStyles } from './template-styles';
import { plus } from '../../shared/icons/plus';
import { image } from '../../shared/icons/image';
import { positionAndName, profileAndName, workAndName } from '../../shared/icons/profile-placeholder';
import { text } from '../../shared/icons/text';

class AddItemDropdown extends LitElement {
    static get properties() {
        return {
            visible: false
        }
    }

    static styles = [
        templateStyles,
        css`
        #add-item-dropdown-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            height: 20px;
            width: 90px;

            padding: 5px 10px;
            font-size: 11px;
            
            border-radius: 3px;
            border: none;
            background: #D8D8D8;
            color: #717171;
        }

        .dropdown-body {
            padding: 0px;
            min-width: 300px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        li {
            padding: 10px 7px;
            display: flex;
        }

        li:hover {
            background-color: #DDEAFA
        }

        .icon {
            height: 25px;
            width: 25px;
            background-size: 20px;
            background-repeat: no-repeat;
            margin-top: auto;
            margin-bottom: auto;
        }

        .li-text {
            font-size: 16px;
            line-height: 22px;
            margin-left: 10px;
            margin-top: auto;
            margin-botton: auto;
        }
    `];

    _itemList = [
        {
            id: 'imageAdd',
            icon: image,
            label: 'Image'
        },
        {
            id: 'textAreaAdd',
            icon: text,
            label: 'Text'
        },
        {
            id: 'profileAndNameAdd',
            icon: profileAndName,
            label: 'Profile & Name'
        },
        {
            id: 'positionAndNameAdd',
            icon: positionAndName,
            label: 'Position with Profile & Name'
        },
        {
            id: 'workAnniversaryAndNameAdd',
            icon: workAndName,
            label: 'Work Anniversary with Profile & Name'
        },
        {
            id: 'custom-profile',
            label: 'Custom'
        }
    ]

    _createItemField(item) {
        return html`
            <li id="${item.id}" @click="${e => this._itemSelect(item.id)}">
                <div class="icon">${item.icon}</div>
                <span class="li-text">${item.label}</span>
            </li>`;
    }

    render() {
        return html`
            <div class="dropdown-container">
                <button 
                    id="add-item-dropdown-label" 
                    @click="${(e) => this._buttonClick(e, true)}"
                    > 
                    Add ${plus}
                </button>
                ${this.visible ?
                    html`
                    <div class="card dropdown-body">
                        <ul>
                            ${this._itemList.map(field => this._createItemField(field))}
                        </ul>
                    </div>`
                : ''}
            </div>`;
    }

    clickHandler = (event) => this._buttonClick(event);

    _buttonClick(event, buttonClick) {
        event.stopPropagation();
        event.preventDefault();

        buttonClick ? this.toggle() : this.setHidden();
    }

    toggle() {
        this.visible ? this.setHidden() : this.setVisible();
    }

    setVisible() {
        this.visible = true;
        document.addEventListener('click', this.clickHandler);
    }

    setHidden() {
        this.visible = false;
        document.removeEventListener('click', this.clickHandler);
    }

    _itemSelect(itemType) {
        this.dispatchEvent(new Event(itemType, {
            bubbles: true,
            composed: true,
            cancelable: true
        }));
    }
}

customElements.define('add-item-dropdown', AddItemDropdown);
